class Complaint {
  get_complaints(page, data) {
    return axios.post("general/complaints?page=" + page, data);
  }
  get_complaint_by_id(id) {
    return axios.post("general/complaints/view/" + id);
  }
  get_create() {
    return axios.get("general/complaints/create");
  }
  create_complaints(form) {
    return axios.post("general/complaints/store", form);
  }
  approve_complaint(form) {
    return axios.post("general/complaints/approve", form)
  }
  close_complaint(id) {
    return axios.post("general/complaints/close/" + id)
  }
  cancel_complaint(id) {
    return axios.post("general/complaints/cancel/" + id)
  }
  get_complaints_counters() {
    return axios.get("general/complaints/reports/counters")
  }
}

export default new Complaint();
