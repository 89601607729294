import i18n from '@/i18n.js'
import ComplaintService from "@/modules/complaints/services/complaint.service";
import Vue from 'vue';
export const complaints = {
    namespaced: true,
    state: () => ({

    }),

    actions: {
        close({ rootState }, item) {

            return ComplaintService.close_complaint(item.id).then(
                (response) => {
                    console.log(response.data)
                    // console.log(response)
                    rootState.table.items.map(v => {
                        if (v.id == item.id) {
                            v.status = response.data.data.status
                            return v;
                        }
                    });
                    return Promise.resolve(response)
                },
                (error) => {
                    console.log(error);
                    return Promise.reject(error)
                    // if (error.response.status != 401) {

                    // }
                }
            );
        },
        cancel({ rootState }, id) {

            return ComplaintService.cancel_complaint(id).then(
                (response) => {
                    console.log(response.data)
                    // console.log(response)
                    rootState.table.items.map(v => {
                        if (v.id == id) {
                            v.status = response.data.data.status
                            return v;
                        }
                    });
                    Promise.resolve(response)
                },
                (error) => {
                    console.log(error);
                    if (error.response.status != 401) {
                        return Promise.reject(error)

                    }
                }
            );
        },




        handlePageChange({ rootState, dispatch }, page) {
            rootState.table.paginate.page = page
            dispatch("getData", {})
        },

        changeItemPage({ rootState, dispatch }, pre_page) {
            rootState.table.paginate.itemsPerPage = parseInt(pre_page);
            rootState.table.paginate.page = 1;
            dispatch("getData", { pre_page, type: "pre_page" })
        },

        // filter section 
        filterData({ rootState, dispatch }) {
            console.log('filter')
            rootState.table.paginate.page = 1;
            dispatch("getData", {})
        },
        resetFilter({ rootState, dispatch }) {
            rootState.table.paginate.page = 1;
            dispatch("getData", { reset: true })
        },

        getData({ rootState }, data) {
            // console.log(data)
            rootState.table.loading = true;
            const formData = new FormData();
            if (!data.hasOwnProperty('reset')) {

                rootState.form.filters.filter(function (filter) {
                    formData.append(filter.name, filter.value);
                });
                console.log('reset1')
            }
            if (data.hasOwnProperty('type') && data.type == "pre_page") {
                formData.append(
                    "paginate",
                    data.pre_page
                );
            } else {
                if (rootState.table.paginate.itemsPerPage != '')
                    formData.append(
                        "paginate",
                        rootState.table.paginate.itemsPerPage
                    );
            }
            return ComplaintService.get_complaints(rootState.table.paginate.page, formData).then(
                (response) => {
                    console.log(response.data);
                    rootState.table.items = response.data.data.data
                    rootState.table.items.forEach((item) => {
                        if (item.content != null && item.content != "") {
                            //split on first occurence of closing paragraph tag
                            //this might need to be expanded to other tags
                            item.content = item.content.split(/<\/p>/)[0] + "...</p>";
                        }
                    });
                    rootState.table.paginate = {
                        total: response.data.data.total,
                        itemsPerPage: parseInt(response.data.data.per_page),
                        row_pre_page: true,
                        page: rootState.table.paginate.page,
                    }
                    if (data.hasOwnProperty('reset') && data.reset) {
                        // console.log('reset2')
                        rootState.form.filters = response.data.filters;
                    }
                    rootState.table.loading = false;
                    return Promise.resolve(response);
                },
                (error) => {
                    console.log(error);
                    rootState.table.loading = false;
                    if (error.response.status != 401) {

                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            )
        },
        getComplaintsCounters({ rootState }) {
            return ComplaintService.get_complaints_counters().then((response) => {
                return Promise.resolve(response);
            },
                (error) => {
                    console.log(error);
                    return Promise.reject(error);
                }
            )
        }

    },

};