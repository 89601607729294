var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center d-flex"},_vm._l((_vm.items),function(item){return (item.permission && _vm.str_per.indexOf(item.permission) > -1)?_c('v-menu',{key:item.title,staticClass:"pa-2 ma-2",attrs:{"offset-y":"","rounded":"rounded"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(
          item.permission == 'employee' &&
          ((_vm.user && _vm.user.parent_id != null) || (_vm.type && _vm.type.code == 'admin'))
        )?void 0:[_c('v-btn',_vm._g(_vm._b({directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],staticClass:"ma-2 head-item",class:_vm.isActive ? 'head-active' : '',attrs:{"elevation":"0","link":""},on:{"click":function($event){_vm.isActive = !_vm.isActive}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"hidden-effect",attrs:{"left":""}},[_vm._v(" "+_vm._s(item.action)+" ")]),_vm._v(" "+_vm._s(_vm.$t(item.title))+" "),(item.items)?_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-chevron-down ")]):_vm._e()],1)]]}}],null,true)},[(item.items)?_c('v-list',{staticClass:"app-bar-list"},_vm._l((item.items),function(child){return (child.permission && _vm.str_per.indexOf(child.permission) > -1)?_c('v-list-item',{key:child.title,staticClass:"list-item",attrs:{"link":"","to":child.link},on:{"click":function($event){_vm.active = !_vm.active}}},[(
            child.permission == 'add-employee' && _vm.type && _vm.type.code == 'admin'
          )?void 0:[(child.permission && _vm.str_per.indexOf(child.permission) > -1)?_c('v-list-item-content',[_vm._v(" "+_vm._s(_vm.$t(child.title))+" ")]):(child.permission == '')?_c('v-list-item-content',[_vm._v(" "+_vm._s(_vm.$t(child.title))+" ")]):_vm._e()]],2):_vm._e()}),1):_vm._e()],1):_vm._e()}),1)}
var staticRenderFns = []

export { render, staticRenderFns }