<template>
  <div class="text-center d-flex">
    <v-menu
      v-if="item.permission && str_per.indexOf(item.permission) > -1"
      v-for="item in items"
      :key="item.title"
      offset-y
      rounded="rounded"
      class="pa-2 ma-2"
    >
      <template v-slot:activator="{ on, attrs }">
        <template
          v-if="
            item.permission == 'employee' &&
            ((user && user.parent_id != null) || (type && type.code == 'admin'))
          "
        ></template>
        <template v-else>
          <v-btn
            elevation="0"
            link
            v-bind="attrs"
            v-on="on"
            class="ma-2 head-item"
            @click="isActive = !isActive"
            :class="isActive ? 'head-active' : ''"
            v-click-outside="onClickOutside"
          >
            <v-icon class="hidden-effect" left>
              {{ item.action }}
            </v-icon>
            {{ $t(item.title) }}

            <v-icon right v-if="item.items"> mdi-chevron-down </v-icon>
          </v-btn>
        </template>
      </template>

      <v-list class="app-bar-list" v-if="item.items">
        <v-list-item
          link
          v-for="child in item.items"
          v-if="child.permission && str_per.indexOf(child.permission) > -1"
          :key="child.title"
          :to="child.link"
          @click="active = !active"
          class="list-item"
        >
          <template
            v-if="
              child.permission == 'add-employee' && type && type.code == 'admin'
            "
          ></template>
          <template v-else>
            <v-list-item-content
              v-if="child.permission && str_per.indexOf(child.permission) > -1"
            >
              {{ $t(child.title) }}
            </v-list-item-content>
            <v-list-item-content v-else-if="child.permission == ''">
              {{ $t(child.title) }}
            </v-list-item-content>
          </template>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import "../../../assets/css/layout.scss";
import { mapGetters, mapState } from "vuex";
export default {
  props: ["items", "header"],
  data() {
    return {
      isActive: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["str_per"]),
    ...mapState("auth", ["user", "type"]),
  },
  methods: {
    listClose($event) {
      this.active = false;
      this.$emit("listClose", $event);
    },
    onClickOutside() {
      this.isActive = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.app-bar-list .v-list-item {
  min-height: auto;
}
.v-menu__content {
  top: 81.3px;
}
.hidden-effect {
  @media screen and (max-width: 1200px) {
    display: none !important;
  }
}
</style>
