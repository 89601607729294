const create = () => import("@/modules/complaints/views/create.vue");
const allComplaints = () => import("@/modules/complaints/views/index.vue");
const view = () => import("@/modules/complaints/views/view.vue");
import ComplaintLayout from "@/views/Layout/ComplaintLayout.vue";
import Middleware from "@/middleware";
export const ComplaintsPage = {
  path: "/",
  component: ComplaintLayout,
  name: "Complaints",
  children: [
    {
      path: "/create-complaints",
      name: "Complaints create",
      component: create,
      meta: {
        groupName: "Complaints",
        middlewares: [
          Middleware.auth,
          Middleware.permission,
          Middleware.signeture,
        ],
        permissions: "complaints-create",
      },
    },
    {
      path: "/complaints",
      name: "Complaints",
      component: allComplaints,
      meta: {
        groupName: "Complaints",
        middlewares: [
          Middleware.auth,
          Middleware.permission,
          Middleware.signeture,
        ],
        permissions: "complaints-index",
      },
    },
    {
      path: "/complaints/view/:id",
      name: "viewComplaints",
      component: view,
      meta: {
        groupName: "Complaints",
        middlewares: [
          Middleware.auth,
          Middleware.permission,
          Middleware.signeture,
        ],
        permissions: "complaints-view",
      },
    },

  ],
};
