<template>
  <div>
    <v-app>
      <drawer
        v-if="drawer && $vuetify.breakpoint.mobile"
        :drawer="drawer"
        :sidebarColor="sidebarColor"
        :sidebarTheme="sidebarTheme"
        :bgDrawer="bgDrawer"
      >
      </drawer>
      <div
        @click="drawer = false"
        v-if="drawer"
        class="position-absolute drawer-state"
      ></div>
      <v-main>
        <app-bar-complaints
          background="bg-lite-white"
          has-bg
          @drawer-toggle="drawer = $event"
          mobile-breakpoint="1024"
        ></app-bar-complaints>

        <!-- <div class="px-6 mt-5"></div> -->
        <div class="main-content pt-5 position-relative">
          <div
            class="overlay"
            :class="bgMainContent ? 'bg-main-content' : ''"
            :style="
              bgMainContent
                ? `
            
            height: ${$route.name != 'Default' ? '300px' : '200px'};`
                : ''
            "
          ></div>
          <fade-transition :duration="200" origin="center top" mode="out-in">
            <!-- your content here -->
            <router-view></router-view>
          </fade-transition>
        </div>
        <!-- <content-footer v-if="!$route.meta.hideFooter"></content-footer> -->
        <v-btn
          :ripple="false"
          icon
          rounded
          color="#fff"
          width="52px"
          height="52px"
          style="z-index: 5555"
          title="عودة الى صفحة تحديد المشعر"
          class="fixed-plugin-button position-fixed btn-light bg-gradient-blue text-white z-index-9999"
          @click="got_to_choose_page"
        >
          <v-icon size="30">mdi-chevron-left py-2</v-icon>
        </v-btn>
        <!-- <settings
          :showSettingsDrawer="showSettingsDrawer"
          @toggleSettingsDrawer="toggleSettingsDrawer"
          @toggleMainBackground="toggleMainBackground"
          @toggleSidebarBackground="toggleSidebarBackground"
        >
        </settings> -->

        <!-- <settings-drawer
          :showSettingsDrawer="showSettingsDrawer"
          @toggleSettingsDrawer="toggleSettingsDrawer"
          @updateSidebarColor="updateSidebarColor"
          @updateSidebarTheme="updateSidebarTheme"
          @toggleNavbarPosition="toggleNavbarPosition"
        >
        </settings-drawer> -->
      </v-main>
    </v-app>
  </div>
</template>
  <script>
// /* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import { mapState } from "vuex";
import { FadeTransition } from "vue2-transitions";
import Drawer from "@/components/Drawer.vue";
import AppBarComplaints from "@/components/AppBarComplaint.vue";
import ContentFooter from "@/components/Footer.vue";
import SettingsDrawer from "@/components/Widgets/SettingsDrawer.vue";
import Settings from "@/components/Widgets/Settings.vue";
import CustomBreadcrumb from "@/components/CustomBreadcrumb";

export default {
  components: {
    CustomBreadcrumb,
    ContentFooter,
    FadeTransition,
    Drawer,
    AppBarComplaints,
    SettingsDrawer,
    Settings,
  },
  data() {
    return {
      drawer: null,
      showSettingsDrawer: false,
      sidebarColor: "success",
      sidebarTheme: "transparent",
      navbarFixed: true,
      bgMainContent: false,
      bgDrawer: false,
    };
  },
  computed: {
    ...mapState("auth", ["type"]),
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
    toggleSettingsDrawer(value) {
      this.showSettingsDrawer = value;
    },
    updateSidebarColor(value) {
      this.sidebarColor = value;
    },
    updateSidebarTheme(value) {
      this.sidebarTheme = value;
    },
    toggleNavbarPosition(value) {
      this.navbarFixed = value;
    },

    toggleMainBackground(value) {
      this.bgMainContent = value;
    },
    toggleSidebarBackground(value) {
      this.bgDrawer = value;
    },
    got_to_choose_page() {
      this.$router.push("/choose");
    },
  },
  mounted() {
    this.initScrollbar();
  },
};
</script>
  <style>
.bg-main-content {
  background-image: url("../../assets/img/tsleem/new-bg-login.jpg");
}
.main-content .overlay {
  /* background: linear-gradient(45deg, #be936f, #d6bca6, #d6bca6); */

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
@media print {
  .print {
    /* border: 1px solid black; */
    border: none;
    border-collapse: collapse;
  }
  .print tr > td {
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    font-size: 12px;
  }
  .print tr > th {
    font-size: 14px;
  }

  .print td:first-child {
    border-left: none;
  }

  .print td:last-child {
    border-right: none;
  }

  .print tr > td:last-of-type {
    display: none;
  }
  .print tr > th:last-of-type {
    display: none;
  }
}
</style>
  