<template>
  <!-- <div class="row"> -->
  <!-- <div class="col-12 col-sm-12 header-container"> -->
  <v-app-bar
    :color="background"
    :height="!navbarFixed || !mainBg ? 80 : ''"
    class=""
    :class="
      navbarFixed || mainBg
        ? 'position-sticky  top z-index-sticky'
        : 'px-0 mx-0'
    "
  >
    <div class="d-flex justify-space-between align-center" style="width: 100%">
      <router-link to="/dashboard">
        <div class="d-flex justify-center align-center image-container">
          <img src="@/assets/img/logo2.png" class="navbar-brand-img my-3" />
          <span
            class="text-brand text-dark ms-2 font-weight-bold font-poppins text-sm hidden-effect"
            >Tsleem</span
          >

          <!-- <ListItemHeader
            @listClose="listClose"
            :items="system"
            :header="header_system"
          ></ListItemHeader> -->
        </div>
      </router-link>
      <div
        class="test"
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 60px;
          z-index: 100;
        "
      >
        <!-- Notifications -->
        <div>
          <v-btn
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-600 text-capitalize drawer-toggler py-3 px-6 rounded-sm"
            :class="{
              'btn-dark-toggler-hover': hasBg,
              'btn-toggler-hover': !hasBg,
              active: !togglerActive,
            }"
            style="cursor: pointer"
            color="transparent"
            @click="drawerClose"
            v-if="$vuetify.breakpoint.mobile"
          >
            <div class="drawer-toggler-inner">
              <i class="drawer-toggler-line bg-gray"></i>
              <i class="drawer-toggler-line bg-gray"></i>
              <i class="drawer-toggler-line bg-gray"></i>
            </div>
          </v-btn>
        </div>
        <div>
          <v-menu
            transition="slide-y-transition"
            offset-y
            offset-x
            min-width="300"
            max-width="300"
            max-height="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                :ripple="false"
                :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
                class="text-body notification-b mx-3"
                :color="linkColor"
                v-bind="attrs"
                v-on="on"
                small
                @click="clear_notification"
              >
                <v-badge
                  color="red"
                  overlap
                  :content="unread_notify"
                  v-if="unread_notify != 0"
                >
                  <v-icon size="16">fa fa-bell text-lg</v-icon>
                </v-badge>
                <v-icon v-else size="16">fa fa-bell text-lg</v-icon>
              </v-btn>
            </template>

            <v-list class="py-0">
              <v-list-item
                v-for="(item, i) in notifications"
                :key="i"
                @click="see_notify(item)"
                :class="item.is_seen == 0 ? 'not_seen' : ''"
                class="pa-4 list-item-hover-active"
              >
                <v-list-item-content class="pa-0">
                  <v-list-item-title
                    class="text-body-2 ls-0 text-typo font-weight-600 mb-0 white-space-break"
                  >
                    <v-row>
                      <v-col>
                        <h6 class="text-sm font-weight-normal mb-1 text-typo">
                          {{ item.message }}
                        </h6>
                      </v-col>
                    </v-row>
                  </v-list-item-title>

                  <p class="text-xs text-secondary mb-0">
                    <v-icon size="12" class="text-secondary"
                      >fa fa-clock me-1</v-icon
                    >
                    {{ item.created_at }}
                  </p>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="read_more"
                class="border-top list-item-hover-active text-center see_more"
                style="background: #d1d5d5"
              >
                <v-list-item-content class="pa-0">
                  <v-list-item-title
                    class="text-body-2 ls-0 list-notify text-typo font-weight-600 mb-0"
                  >
                    <div>
                      <v-btn
                        @click.capture.native.stop="read_more_method"
                        style="background-color: transparent"
                        class="w-100 text-xs font-weight-normal text-typo box-shadow-none"
                      >
                        {{ $t("See more") }}
                      </v-btn>
                    </div>
                    <!-- <v-row>
                <v-col>
                </v-col>
              </v-row> -->
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>

          <!--   User Dropdown   -->
          <v-menu
            transition="slide-y-transition"
            offset-y
            min-width="250"
            max-width="250"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="text-body btn-hover box-shadow-none hover-effect"
                :ripple="false"
                color="transparent"
                style="box-shadow: none; padding: 0"
                v-bind="attrs"
                v-on="on"
                v-if="user"
              >
                <!-- <div class="profile-details"> -->
                <div class="d-flex flex-column align-start hidden-effect">
                  <span>{{ fullName }}</span>
                  <span> {{ accountType }} </span>
                </div>
                <!-- </div> -->
                <v-divider vertical class="mx-2" />
                <v-avatar>
                  <img
                    v-if="user.avatar"
                    :src="user.avatar"
                    alt="Avatar"
                    class="border-radius-lg"
                  />
                  <img
                    v-else
                    src="@/assets/img/no-avatar.jpg"
                    alt="Avatar"
                    class="border-radius-lg"
                  />
                </v-avatar>
              </v-btn>
            </template>

            <!-- here -->
            <v-list class="py-0">
              <v-list-item class="pa-4 list-item-hover-active">
                <v-list-item-content class="pa-0">
                  <v-list-item-title
                    class="text-body-2 ls-0 text-typo font-weight-600 mb-0"
                  >
                    <v-row>
                      <v-col>
                        <router-link
                          to="/profile"
                          style="text-decoration: none"
                        >
                          <h6 class="text-sm font-weight-normal mb-1 text-typo">
                            <v-icon size="16"
                              >fa fa-user me-sm-2 text-sm</v-icon
                            >
                            {{ $t("general.profile") }}
                          </h6>
                        </router-link>
                      </v-col>
                    </v-row>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item @click="logout" class="pa-4 list-item-hover-active">
                <v-list-item-content class="pa-0">
                  <v-list-item-title
                    class="text-body-2 ls-0 text-typo font-weight-600 mb-0"
                  >
                    <v-row>
                      <v-col>
                        <h6 class="text-sm font-weight-normal mb-1 text-typo">
                          <v-icon size="16"
                            >fas fa-sign-out-alt me-sm-2 text-sm</v-icon
                          >
                          {{ $t("logout") }}
                        </h6>
                      </v-col>
                    </v-row>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </div>
  </v-app-bar>
  <!-- </div> -->
  <!-- <div class="col-lg-3 col-md-12 d-flex align-end justify-center py-3">
    </div> -->
  <!-- </div> -->
</template>
<script>
import "@/assets/css/layout.scss";
import CustomBreadcrumb from "@/components/CustomBreadcrumb";
import ListItemHeader from "../views/Current/Components/ListItemHeader.vue";

import { dashboard } from "@/modules/dashboard/drawer/dashboard";
import { user } from "@/modules/users/drawer/users";
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "app-bar-user",
  components: { CustomBreadcrumb, ListItemHeader },
  props: {
    background: String,
    hasBg: Boolean,
    linkColor: String,
    toggleActive: String,
    width: String,
    navbarFixed: {
      type: Boolean,
      default: false,
    },
    mainBg: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    system: [dashboard, user],
    drawer: false,
    togglerActive: false,
    count: 1,
    language: [
      { title: '<span class="font-weight-bold">EN</h6>', abbr: "en" },
      { title: '<span class="font-weight-bold">AR</h6>', abbr: "ar" },
    ],
  }),
  computed: {
    ...mapState("auth", ["user", "type"]),
    ...mapState("notifications", [
      "unread_notify",
      "notifications",
      "read_more",
    ]),
    classLang() {
      return {
        "ml-5": this.$vuetify.rtl,
        "mr-5": !this.$vuetify.rtl,
      };
    },
    styleDropDown() {
      return {
        "left:125px": this.$vuetify.rtl,
        "left:225px": !this.$vuetify.rtl,
      };
    },
    fullName() {
      let user = "";
      if (this.user.name != null) user = this.user.name;
      return user;
    },
    accountType() {
      return this.type.name ? this.type.name : "";
    },
    dropdownItems() {
      return this.itemsPages.map((page) => ({
        title: page.title,
      }));
    },
  },
  methods: {
    ...mapActions("notifications", [
      "increaseUnreadMessage",
      "seeMoreNotifications",
    ]),
    ...mapMutations("notifications", ["SET_COUNT_UNREAD_MESSAGE"]),
    clear_notification() {
      if (this.unread_notify > 0)
        this.$http
          .post("general/notification/clear_notification")
          .then((response) => {
            this.SET_COUNT_UNREAD_MESSAGE(0);
          });
    },
    see_notify(item) {
      this.$http
        .get("general/notification/make_notification_seen/" + item.id)
        .then((response) => {
          if (item.is_seen == 0) {
            item.is_seen = 1;
            this.increaseUnreadMessage();
          }
          window.location = item.link;
          // if (this.$route.path != item.link) this.$router.push(item.link);
        });
    },
    read_more_method() {
      this.count++;
      this.seeMoreNotifications(this.count);
    },
    logout() {
      this.$store
        .dispatch("auth/logout")
        .then((response) => {
          console.log(response);
          this.$router.push("/login");
        })
        .catch((err) => console.log(err));
    },
    change_lang(lang) {
      localStorage.setItem("lang", lang);
      window.location.reload();
    },
    drawerClose() {
      this.togglerActive = !this.togglerActive;
      this.$emit("drawer-toggle", this.togglerActive);
    },
    minifyDrawer() {
      if (this.$vuetify.breakpoint.mobile) {
        this.drawerClose();
        return;
      }
      this.togglerActive = !this.togglerActive;
      this.mini = !this.mini;
      const body = document.getElementsByTagName("body")[0];

      if (body.classList.contains("drawer-mini")) {
        body.classList.remove("drawer-mini");
      } else {
        body.classList.add("drawer-mini");
      }
    },
    listClose(event) {
      let items;
      let headers;
      let groups;
      let currentEl;

      if (
        document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link.item-active"
        )
      ) {
        items = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link.item-active"
        );
      }

      if (
        document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group__header.v-list-item--active"
        )
      ) {
        headers = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group__header.v-list-item--active"
        );
      }

      if (
        document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group .v-list .v-list-group.v-list-group--active, .mb-0.v-list-item.v-list-item--link .v-list-group.v-list-group--active"
        )
      ) {
        groups = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group .v-list .v-list-group.v-list-group--active, .mb-0.v-list-item.v-list-item--link .v-list-group.v-list-group--active"
        );
      }

      if (
        event.target.closest(
          ".mb-0.v-list-item.v-list-item--link .v-list-item__content.ms-6 .v-list-group"
        )
      ) {
        currentEl = event.target.closest(
          ".mb-0.v-list-item.v-list-item--link .v-list-item__content.ms-6 .v-list-group"
        );
      }

      if (items != null) {
        for (var i = 0; i < items.length; i++) {
          items[i].classList.remove("item-active");
        }
      }

      if (headers != null) {
        for (var j = 0; j < headers.length; j++) {
          headers[j].classList.remove(
            "v-list-item--active",
            "item-active",
            "primary--text"
          );
          headers[j].setAttribute("aria-expanded", false);
        }
      }

      if (groups != null) {
        for (var k = 0; k < groups.length; k++) {
          groups[k].classList.remove("v-list-group--active", "primary--text");
        }
      }

      if (event.target.closest(".mb-0.v-list-item.v-list-item--link")) {
        event.target
          .closest(".mb-0.v-list-item.v-list-item--link")
          .classList.add("item-active");
      }

      if (currentEl != null) {
        currentEl
          .querySelector(".v-list-group__header")
          .classList.add("v-list-item--active", "item-active");
      }
    },
  },
  watch: {
    toggleActive(val) {
      this.togglerActive = val;
    },
  },
  mounted() {
    console.log("system", this.system);
    console.log("header_system", this.header_system);
  },
};
</script>
<style lang="scss" scoped>
.see_more.v-list-item {
  padding: 0 !important;
}
.my-toolbar {
  width: 1050px;
  @media screen and (max-width: 1900px) {
    width: 600px;
  }
  @media screen and (max-width: 1263px) {
    width: 500px;
  }
}

.image-container {
  width: 110px;
  @media screen and (max-width: 1263px) {
    width: 75px;
  }
}
.navbar-brand-img {
  width: 27%;
  @media screen and (max-width: 1263px) {
    width: 50%;
  }
}
/*
// .hover-effect{
// display: flex;
// justify-content: end;
//     .profile-details{
//     width: 0px;
//     overflow: hidden;
  
// }

// &:hover{
//     .profile-details{
//         width: 100%;
//         transition: 0.3s all;
        
//     }
// }}
// .notification-b{
//     display: flex;
//     justify-content: start;
// }
*/
.user-bar {
  overflow-x: auto;
  overflow-y: visible !important;
  height: auto !important;
  &::-webkit-scrollbar {
    height: 6px !important;
  }
}
@media screen and (max-width: 1264px) {
  .test {
    width: 100%;
  }
  .header-container {
    display: none !important;
  }
}
@media screen and (max-width: 960px) {
  .user-bar {
    display: none !important;
  }
}

.theme--light.v-app-bar.v-toolbar.v-sheet {
  background-color: #ffff;
  box-shadow: none;
  // padding-top: 11px;
  height: 80px;
}
</style>
