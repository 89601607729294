import Vue from "vue";
import VueRouter from "vue-router";
import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import BlankLayout from "../views/Layout/BlankLayout.vue";
import AuthCoverLayout from "@/views/Layout/AuthCoverLayout";
import Middleware from "@/middleware/";


const BlankRegisterDone = () => import("@/modules/auth/views/Done.vue");

Vue.use(VueRouter);

/************** Start Routes ***********************/

let FilePage = {
    path: "/",
    component: BlankLayout,
    name: "FileSignaturePage",
    children: [


        {
            path: "/done",
            name: "BlankRegisterDone",
            component: BlankRegisterDone,
            meta: {
                groupName: "Done",
                middlewares: [Middleware.authReject],
            },
        },


        {
            path: "/complaints/report/:id",
            name: "viewReport",
            component: () => import("@/modules/complaints/views/report.vue"),
            meta: {
                groupName: "Complaints",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                // permissions: "complaints-view",
            },
        },
    ],
};

let PageError = {
    path: "/error",
    component: AuthCoverLayout,
    name: "Errors",
    children: [{
        path: "/403",
        meta: {

            title: "403",
        },
        name: "403",
        component: () =>
            import("@/views/Error/403.vue"),
    },
        // {
        //     path: "/404",
        //     name: "404",
        //     component: () =>
        //         import("@/views/Error/403.vue"),
        // },
    ],
};
// import {
//     dashboardPage,
// } from "@/modules/dashboard/routes/dashboard"
let Choose = {
    path: "/",
    component: BlankLayout,
    name: "Choos",
    children: [{
        path: "/choose",
        meta: {
            middlewares: [
                Middleware.auth,
            ],
        },
        name: "choose",
        component: () =>
            import("@/modules/auth/views/Choose.vue"),
    },
    ]
}

import {
    Login,
    // SignUp,
    // RegisterFormPages,
} from "@/modules/auth/routes/auth"
import { ComplaintsPage } from "../modules/complaints/routes/complaints";

// import { Roles, TypesPage, EmployeePage, Admins, categoriesPage, alliances } from "@/modules/users/routes/users";

/************** End Routes ***********************/
const routes = [
    {
        path: "/",
        name: "Dashboard",
        redirect: "/choose",
        component: DashboardLayout,
        children: [
            {
                path: "/testing-api",
                name: "test",
                component: () => import("@/views/Current/Components/Test.vue"),
                meta: {
                    groupName: "Dashboards",
                    middlewares: [
                        Middleware.auth,
                        Middleware.is_admin,
                        Middleware.permission,
                        Middleware.signeture,
                    ],
                    // permissions: "dashboard-optimization-sites",
                },
            },
        ],

    },

    Login,
    ComplaintsPage,
    FilePage,
    PageError,
    Choose,
    {
        path: "*",
        component: AuthCoverLayout,
        name: "Error",
        redirect: "/404",
        children: [
            {
                path: "/404",
                meta: {
                    title: "404",
                },
                name: "404",
                component: () => import("@/views/Error/404.vue"),
            },
        ],
    },
];

const router = new VueRouter({
    mode: "history",
    routes,
});


import i18n from '@/i18n.js'
router.afterEach((to, from) => {
    Vue.nextTick(() => {
        if (to.meta.title)
            document.title = i18n.t(to.meta.title);
    });
});

export default router;
